import "./App.css";
import { GlobalStyles } from "twin.macro";
import AOS from "aos";
import "aos/dist/aos.css";
// import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/styles.css";
import { RouteContainer } from "./pages";
import { BrowserRouter as Router } from "react-router-dom";

AOS.init();
function App() {
  return (
    <Router>
      <GlobalStyles />
      <RouteContainer />
    </Router>
  );
}

export default App;
