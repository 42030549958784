import { Route, Routes } from "react-router-dom";
import React, { Suspense } from "react";
import LoadingSpin from "../components/base/Loading";
import router from "../constant/router";

const Home = React.lazy(() => import("./home"));
const Ecosystem = React.lazy(() => import("./ecosystem"));
const NotFound = React.lazy(() => import("./not-found"));

export const RouteContainer = () => (
  <Suspense fallback={<LoadingSpin />}>
    <Routes>
      <Route path={router.home} element={<Home />} />
      <Route path={router.ecosystem} element={<Ecosystem />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </Suspense>
);
